import './coreConfig'

import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { createBrowserHistory } from 'history'

import Scroll from 'helper/scroll'
import appConfigs from 'config'
import configureStore from './store'

import appRoutes from './router'

import 'moment/min/locales'
import AccessControl from './accessControl'

const store = configureStore()
const browserHistory = createBrowserHistory()
const accessControl = new AccessControl(store, browserHistory)
accessControl.checkRoute(browserHistory.location.pathname)

class Root extends React.Component {
  constructor(props) {
    super(props)
    this.unlistenRouterChanged = null
  }

  componentDidMount() {
    this.unlistenRouterChanged = browserHistory.listen((location, action) => {
      accessControl.checkRoute(location.pathname)
      Scroll.scrollTop('#root', 0)
    })
    Scroll.init('#root')
    
  }

  componentWillUnmount() {
    if (this.unlistenRouterChanged) {
      this.unlistenRouterChanged()
    }
  }

  render() {
    return (
      <Provider store={store}>        
        <Router history={browserHistory}>{renderRoutes(appRoutes)}</Router>
      </Provider>        
    )
  }
}

export default Root
