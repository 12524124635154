import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import appConfigs from 'config';

const time12to24Mode = ({ time }) => {
    let timeBlockParts = time.split(' ');
    if (timeBlockParts.length !== 2) {
        return '';
    }

    let timeString = timeBlockParts[0].trim();
    let timePeriod = timeBlockParts[1].trim();
    if (!timeString || !timePeriod) {
        return '';
    }
    if (timePeriod !== 'PM' && timePeriod !== 'AM') {
        return '';
    }

    let timeParts = timeString.split(':');
    if (timeParts.length !== 2) {
        return '';
    }

    let hour = timeParts[0].trim();
    let minute = timeParts[1].trim();
    if (isNaN(hour) || isNaN(minute)) {
        return '';
    }

    hour = parseInt(hour);
    minute = parseInt(minute);
    if (timePeriod === 'PM') {
        hour += 12;
    }
    return `${hour > 9 ? '' : '0'}${hour}:${minute > 9 ? '' : '0'}${minute}`;
};
window.$(document).on('click', '.stop-propagation-jquery', function (event) {
    event.stopPropagation();
});
window.$(document).ready(() => {
    window.toastr.options = {
        'closeButton': true,
        'debug': false,
        'positionClass': 'toast-top-right',
        'onclick': null,
        'showDuration': '1000',
        'hideDuration': '1000',
        'timeOut': '5000',
        'extendedTimeOut': '1000',
        'showEasing': 'swing',
        'hideEasing': 'linear',
        'showMethod': 'fadeIn',
        'hideMethod': 'fadeOut',
        'maxOpened': 1
    };

    // const tryInitAccountKit = () => {
    //     setTimeout(() => {
    //         if (window.AccountKit.init) {
    //             window.AccountKit.init(
    //                 {
    //                     appId: appConfigs.FACEBOOK.APP_ID,
    //                     state: appConfigs.FACEBOOK.ACCOUNT_KIT.STATE,
    //                     version: appConfigs.FACEBOOK.ACCOUNT_KIT.API_VERSION,
    //                     fbAppEventsEnabled: appConfigs.FACEBOOK.ACCOUNT_KIT.EVENT_ENABLE,
    //                     redirect: appConfigs.FACEBOOK.ACCOUNT_KIT.REDIRECT_URL,
    //                     debug: appConfigs.FACEBOOK.ACCOUNT_KIT.DEBUG
    //                 }
    //             );
    //         } else {
    //             tryInitAccountKit();
    //         }
    //     }, 500);
    // };
    // tryInitAccountKit();

    window.$.validator.addMethod("email", function (value, element) {
        return this.optional(element) || /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)
    }, "Please enter a valid Email.");
    window.$.validator.addMethod('phone', function (value, element) {
        return this.optional(element) || (value.length >= 10 && value.length <= 12 && value.match(/^\+?\d*$/));
    }, 'Please specify a valid phone number');
    window.$.validator.addMethod('username', function (value, element) {
        return this.optional(element) || value.match(/^[a-zA-Z0-9\+\-\.\_]+$/);
    }, 'Please specify a valid value');
    window.$.validator.addMethod('identity', function (value, element) {
        return this.optional(element) || (value.length >= 9 && value.length <= 12 && value.match(/^\+?\d*$/));
    }, 'Please specify a valid identity number');
    window.$.validator.addMethod('amount', function (value, element) {
        return this.optional(element) || (value >= 500000 && value.match(/^\+?\d*$/));
    }, 'Please specify a valid amount number');
    window.$.validator.addMethod('dategt', function (value, element, param) {
        let compareElement = window.$(param);
        return this.optional(element) || this.optional(compareElement[0]) || window.moment(value, element.getAttribute('format')).unix() > window.moment(compareElement.val(), compareElement.attr('format')).unix();
    }, 'Please specify a greater date');
    window.$.validator.addMethod('datest', function (value, element, param) {
        let compareElement = window.$(param);
        return this.optional(element) || this.optional(compareElement[0]) || window.moment(value, element.getAttribute('format')).unix() < window.moment(compareElement.val(), compareElement.attr('format')).unix();
    }, 'Please specify a smaller date');
    window.$.validator.addMethod('time12gt24', function (value, element, param) {
        let compareElement = window.$(param);
        let currentValue = time12to24Mode({ time: value });
        let compareValue = compareElement.val();
        return this.optional(element) || this.optional(compareElement[0]) || currentValue > compareValue;
    }, 'Please specify a greater time');
    window.$.validator.addMethod('time12st24', function (value, element, param) {
        let compareElement = window.$(param);
        let currentValue = time12to24Mode({ time: value });
        let compareValue = compareElement.val();
        return this.optional(element) || this.optional(compareElement[0]) || currentValue < compareValue;
    }, 'Please specify a smaller time');
    window.$.validator.addMethod('time12gt12', function (value, element, param) {
        let compareElement = window.$(param);
        let currentValue = time12to24Mode({ time: value });
        let compareValue = time12to24Mode({ time: compareElement.val() });
        return this.optional(element) || this.optional(compareElement[0]) || currentValue > compareValue;
    }, 'Please specify a greater time');
    window.$.validator.addMethod('time12st12', function (value, element, param) {
        let compareElement = window.$(param);
        let currentValue = time12to24Mode({ time: value });
        let compareValue = time12to24Mode({ time: compareElement.val() });
        return this.optional(element) || this.optional(compareElement[0]) || currentValue < compareValue;
    }, 'Please specify a smaller time');
    // window.$.validator.setDefaults({
    //     ignore: ":hidden, [contenteditable='true']:not([name])"
    // });

    window.$(document).on('show.bs.dropdown', '.table', (e) => {
        var dropdown = window.$(e.target).find('.dropdown-menu');
        dropdown.appendTo('body');
        window.$(this).on('hidden.bs.dropdown', () => {
            dropdown.appendTo(e.target);
        })
    });
    window.$('#root').on('ps-scroll-y', (event) => {
        window.$('.dropdown-menu.show').removeClass('show');
    });
});