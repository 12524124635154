const appConfigs = {
  LOGGER: {
    REDUX: false,
    PAGE_MESSAGE: false,
    SIP: false
  },
  API: {
    ROOT_URL: 'https://api.telepro.me/api/v1',
    CONNECT_GOOGLE_URL: 'https://api.telepro.me/oauth2callback.php?c=new_auth',
    GET_SHEET_URL: 'https://api.telepro.me/oauth2callback.php?c=get_sheets'
  },
  REQUEST: {
    TIMEOUT: 600000
  },
  AZSTACK: {
    CONFIG: {
      REQUEST_TIMEOUT: 60000,
      INBOUND_TIMEOUT: 180000,
      INTERNAL_PING_TIME: 60000,
      AUTO_RECONNECT: true,
      AUTO_RECONNECT_LIMIT_TRIES: 10,
      AUTO_RECONNECT_INTERVAL_TIME: 5000,
      LOG_LEVEL: 'NONE'
    },
    AUTHENTICATION: {
      APP_ID: '9443be36cc36603f7a79fed17e1865d2',
      PUBLIC_KEY:
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2PkTzpmCKRjZ9eE6QCwVch5z/kXLm1+2/v77297LbuGNVtOYymVghgCc6rOtnDW+VdOrntwul/PP/r90Zx4dgr+Hrluimm3ssfwuNKinRcwUrfIiPlkoT4+X+6NAqQISjW0pxL7WClYBwexOYo/ggiZKuHW0tbNicy1fZebOL/ks4lVHuHsGAoMfTBNol5/LJsNA0MdRRp9FUVWWEEZ4evWReDogHuJigHE12PF+GUVgMTXmeNpRYSd7KrBD9q+C1VcsmcpfxtujyfLxPvVDBSXANKmYNFLjwZDDHaoGvz+1ZdH6MM0Fl0QfL5eGC48rsGcz0mnPyV9m6i2MjvaQUwIDAQAB',
      NAMESPACE: ''
    }
  },
  FACEBOOK: {
    APP_ID: '2076938309223251',
    ACCOUNT_KIT: {
      STATE: 'TelePro_CMS_Authentication',
      API_VERSION: 'v1.0',
      EVENT_ENABLE: true,
      REDIRECT_URL: '',
      DEBUG: false
    }
  },
  CSKH: {
    BASE_URL: 'cskh.app',
    EMAIL_SUPPORT: 'info@cskh.app'
  },
  AUTHENTICATED_DATA: {
    EXPIRED_TIME: 365
  },
  EXTERNAL_LINKS: {
    TELEPRO: 'http://www.telepro.vn/vi/homepage-02/',
    TELEPRO_ABOUT: 'http://www.telepro.vn/vi/ve-telepro-2/',
    TELEPRO_TEAM: 'http://www.telepro.vn/vi/ve-telepro-2/',
    TELEPRO_CONTACT: 'http://www.telepro.vn/vi/lien-he-telepro/',
    TELEPRO_PRIVACY: 'http://www.telepro.vn/vi/lien-he-telepro/',
    TELEPRO_LEGAL: 'http://www.telepro.vn/vi/lien-he-telepro/',
    JOB_TEST_TAKE_PART_1: 'https://test.telepro.me/main/test/free/',
    JOB_TEST_TAKE_PART_2: '/take?app_id=1&app_token=',
    JOB_TEST_TAKE_PART_3: '&subscribe_id=',
    JOB_TEST_TAKE_PART_4: '&app_uid='
  },
  TOPUP_BANK_ACCOUNTS: [
    {
      BANK_NAME: 'Vietcom Bank (VCB)',
      ACCOUNT_NUMBER: '0451000435718',
      ACCOUNT_NAME: 'Mai Duy Quang'
    },
    {
      BANK_NAME: ' Ngân hàng TMCP Á Châu (ACB)',
      ACCOUNT_NUMBER: '156917329',
      ACCOUNT_NAME: 'Mai Duy Quang'
    },
    {
      BANK_NAME: 'Ngân hàng Shinhan (Shinhan Bank)',
      ACCOUNT_NUMBER: '8629316',
      ACCOUNT_NAME: 'Mai Duy Quang'
    }
  ],
  SOCKET: {
    MONITORING_URL: 'https://monitor.telepro.me:3001'
  },
  TWILIO: {
    CONFIG: {
      DEBUG: false
    }
  },
  CONTACT: {
    MAX_TRY_GET_TIMES: 3
  },
  TEST_MODE: false,
  TEST_PARAMS: {
    CONTACT: '0836634565',
    CALL_CENTER: '02473068800'
  },
  WEB_VERSION: {
    VERSION: 'v1.0.0',
    BUILD: '202005042132'
  },
  CRYPTO: {
    KEY: '7R7zX2Urc7qvjhkr',
    IV: {
      START: 0,
      END: 32
    },
    CIPHER_TEXT: {
      START: 64,
      END: 88
    },
    SIP: {
      CONFIG: {}
    }
  }
}

export default appConfigs
