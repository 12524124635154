export default class Jquery {
    static waitForEl = function (selector, callback, maxTimes = false) {
        if (window.$(selector).length) {
            callback();
        } else {
            if (maxTimes === false || maxTimes > 0) {
                (maxTimes !== false) && maxTimes--;
                setTimeout(function () {
                    Jquery.waitForEl(selector, callback, maxTimes);
                }, 100);
            }
        }
    };
}