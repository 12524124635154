import * as actionTypes from './actionTypes';
import * as themeConstant from 'constant/theme';

const initalState = {
    current: themeConstant.THEME_TYPE_CLASSIC
};

const themeReducers = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_THEME_RETURN:
            return { current: action.payload.newTheme };
        default:
            return { ...state };
    }
};

export default themeReducers;