import Jquery from 'helper/jquery'

class Scroll {
  static init(target) {
    return Jquery.waitForEl(target, () => new window.PerfectScrollbar(target))
    // return new window.PerfectScrollbar(target)
  }

  static scrollTop(target, offset = 0) {
    window.$(target).scrollTop(offset)
  }
}

export default Scroll
