import * as actionTypes from './actionTypes';

const initalState = {
    current: null
};

const companyReducers = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_COMPANY_RETURN:
            return { current: action.payload.newCompany };
        default:
            return { ...state };
    }
};

export default companyReducers;