import * as appStateConstant from 'constant/appState'
import * as routeConstant from 'constant/route'
import * as userConstant from 'constant/user'
import * as cookiesConstant from 'constant/cookies'
import * as partnerConstant from 'constant/partner'

import Cookies from 'helper/cookies'

class AccessControl {
  constructor(store, browserHistory) {
    this.store = store
    this.browserHistory = browserHistory
  }

  routeToDefault(userType) {
    switch (userType) {
      case userConstant.USER_TYPE_ADMIN:
        this.browserHistory.replace(
          `/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD}/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD_ADMIN}`
        );
        break;
      case userConstant.USER_TYPE_ENTERPRISE:
        this.browserHistory.replace(
          `/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD}/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD_ENTERPRISE}`
        );
        break;
      case userConstant.USER_TYPE_AGENT:
        this.browserHistory.replace(
          `/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD}/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD_AGENT}`
        );
        break;
      case userConstant.USER_TYPE_QC:
        this.browserHistory.replace(
          `/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD}/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD_QC}`
        );
        break;
      default:
        break;
    }
  }

  hasAccessDashboard(pathname) {
    const state = this.store.getState()
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD_ADMIN}`) > -1) {
      return [userConstant.USER_TYPE_ADMIN].indexOf(state.appAuthentication.current.user.type) > -1
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD_ENTERPRISE}`) > -1) {
      return [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(state.appAuthentication.current.user.type) > -1
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD_AGENT}`) > -1) {
      return [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_AGENT].indexOf(state.appAuthentication.current.user.type) > -1
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD_QC}`) > -1) {
      return [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_QC].indexOf(state.appAuthentication.current.user.type) > -1
    }
    return true;
  }

  hasAccessReport(pathname) {
    const state = this.store.getState()
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_REPORT_PARTNER}`) > -1) {
      return [userConstant.USER_TYPE_ADMIN].indexOf(state.appAuthentication.current.user.type) > -1
    }
    return true;
  }

  hasAccessAdmin(pathname) {    
    const state = this.store.getState()    

    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_ADMIN}`) > -1 && pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_ADMIN_CALL_MANAGEMENT}`) > -1) {
      return [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(state.appAuthentication.current.user.type) > -1
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_ADMIN}`) > -1) {
      return [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_QC].indexOf(state.appAuthentication.current.user.type) > -1
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_ADMIN_KYC}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN].indexOf(state.appAuthentication.current.user.type) > -1 &&
        (!state.appAuthentication.current.user.partner_id || state.appAuthentication.current.user.partner_info.type === partnerConstant.PARTNER_TYPE_HUB)
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_ADMIN_PARTNER}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN].indexOf(state.appAuthentication.current.user.type) > -1 &&
        !state.appAuthentication.current.user.partner_id
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_ADMIN_TOPUP}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN].indexOf(state.appAuthentication.current.user.type) > -1 &&
        !state.appAuthentication.current.user.partner_id
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_ADMIN_DEDUCT}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN].indexOf(state.appAuthentication.current.user.type) > -1 &&
        !state.appAuthentication.current.user.partner_id
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_ADMIN_SMS}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN].indexOf(state.appAuthentication.current.user.type) > -1 &&
        !state.appAuthentication.current.user.partner_id
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_ADMIN_CALL_CENTER_CONFIGURATION}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN].indexOf(state.appAuthentication.current.user.type) > -1 &&
        !state.appAuthentication.current.user.partner_id
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_ADMIN_SYSTEM_MONITOR}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN].indexOf(state.appAuthentication.current.user.type) > -1 &&
        !state.appAuthentication.current.user.partner_id
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_ADMIN_REPORT}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN].indexOf(state.appAuthentication.current.user.type) > -1 &&
        !state.appAuthentication.current.user.partner_id
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_ADMIN_C3B_PUSH}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN].indexOf(state.appAuthentication.current.user.type) > -1 &&
        !state.appAuthentication.current.user.partner_id
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_ADMIN_CALL_QC}`) > -1) {      
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_QC].indexOf(state.appAuthentication.current.user.type) > -1 &&
        !state.appAuthentication.current.user.partner_id
      )
    }

    return true
  }

  hasAccessCompany(pathname) {
    const state = this.store.getState()
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_COMPANY_LIST}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_COMPANY_FORM}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    return true
  }

  hasAccessJob(pathname) {
    const state = this.store.getState()
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_LIST}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_SHARED}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE, userConstant.USER_TYPE_AGENT].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_C3B_REPORT}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_FORM}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE, userConstant.USER_TYPE_AGENT].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_CONVERSION_RATE}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_MINE}`) > -1) {
      return [userConstant.USER_TYPE_AGENT].indexOf(state.appAuthentication.current.user.type) > -1
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_CALL}`) > -1) {
      return [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_AGENT].indexOf(state.appAuthentication.current.user.type) > -1
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_CALL_HISTORY}`) > -1) {
      return [userConstant.USER_TYPE_AGENT].indexOf(state.appAuthentication.current.user.type) > -1
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_FOLLOW}`) > -1) {
      return [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_AGENT].indexOf(state.appAuthentication.current.user.type) > -1
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_FOLLOW_POOL}`) > -1) {
      return [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_AGENT].indexOf(state.appAuthentication.current.user.type) > -1
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_FOLLOW_DATA}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_AGENT].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_CALL_DATA}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE, userConstant.USER_TYPE_AGENT].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_IMPORT}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_DASHBOARD}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_STATISTIC}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_REPORT}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE, userConstant.USER_TYPE_AGENT].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_TRANSACTIONS}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_CALL_RESULT}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE, userConstant.USER_TYPE_AGENT].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_JOB_CALL__RESULT_DETAILS}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE, userConstant.USER_TYPE_AGENT].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    return true
  }

  hasAccessData(pathname) {
    const state = this.store.getState()
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_DATA_LIST}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_DATA_CONTACT}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_DATA_MONITORING}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_DATA_FACEBOOK_COMMENT_TRACKING}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    return true
  }

  hasAccessKycRegister(pathname) {
    const state = this.store.getState()
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_KYC}/${routeConstant.ROUTE_NAME_KYC_REGISTER}`) > -1) {
      return [userConstant.USER_TYPE_AGENT].indexOf(state.appAuthentication.current.user.type) > -1
    }
    return true
  }

  hasAccessShop(pathname) {
    const state = this.store.getState()
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_SHOP_PRODUCT}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_SHOP_ORDER}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_SHOP_SHIPPING}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    return true
  }

  hasAccessInbound(pathname) {
    const state = this.store.getState()
    // if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_INBOUND_MISSED_CALL}`) > -1) {
    //   return (
    //     [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
    //       state.appAuthentication.current.user.type
    //     ) > -1
    //   )
    // }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN_INBOUND_CONFIGURATION}`) > -1) {
      return (
        [userConstant.USER_TYPE_ADMIN, userConstant.USER_TYPE_ENTERPRISE].indexOf(
          state.appAuthentication.current.user.type
        ) > -1
      )
    }
    return true
  }

  checkRoute(pathname) {
    const state = this.store.getState()
    if (
      pathname !== routeConstant.ROUTE_NAME_SPLASH &&
      state.appState.initialized !== appStateConstant.APP_STATE_INITIALIZED_YES
    ) {
      Cookies.set(cookiesConstant.COOKIES_KEY_INITIAL_URL, pathname)
      this.browserHistory.push(`${routeConstant.ROUTE_NAME_SPLASH}`)
      return
    }
    if (pathname.indexOf(routeConstant.ROUTE_NAME_AUTHENTICATION) > -1 && state.appAuthentication.current) {
      this.browserHistory.push(`/${routeConstant.ROUTE_NAME_SPLASH}`)
      return
    }
    if (pathname.indexOf(routeConstant.ROUTE_NAME_MAIN) > -1 && !state.appAuthentication.current) {
      this.browserHistory.push(
        `/${routeConstant.ROUTE_NAME_AUTHENTICATION}/${routeConstant.ROUTE_NAME_AUTHENTICATION_SIGNIN}`
      )
      return
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_KYC}/${routeConstant.ROUTE_NAME_KYC_REGISTER}`) === -1 &&
      state.appAuthentication.current &&
      state.appAuthentication.current.user.type === userConstant.USER_TYPE_AGENT &&
      (!state.appAuthentication.current.user.kyc || state.appAuthentication.current.user.kyc.verified !== 1) &&
      (state.appState.domain.id === null || state.appState.domain.type === partnerConstant.PARTNER_TYPE_HUB)
    ) {
      if (!state.appAuthentication.current.user.kyc) {
        this.browserHistory.push(`/${routeConstant.ROUTE_NAME_KYC}/${routeConstant.ROUTE_NAME_KYC_REGISTER}`)
        return
      }
      if (state.appAuthentication.current.user.kyc) {
        if (state.appAuthentication.current.user.kyc.verified !== 1) {
          this.browserHistory.push(`/${routeConstant.ROUTE_NAME_KYC}/${routeConstant.ROUTE_NAME_KYC_REGISTER}`)
          return
        }
      }
    }
    if (pathname.indexOf(`/${routeConstant.ROUTE_NAME_KYC}/${routeConstant.ROUTE_NAME_KYC_REGISTER}`) > -1) {
      if (state.appState.domain.id !== null && state.appState.domain.type !== partnerConstant.PARTNER_TYPE_HUB) {
        this.browserHistory.push(`/${routeConstant.ROUTE_NAME_SPLASH}`)
        return
      }
      if (
        (state.appState.domain.id === null || state.appState.domain.type === partnerConstant.PARTNER_TYPE_HUB) &&
        state.appAuthentication.current &&
        (state.appAuthentication.current.user.type !== userConstant.USER_TYPE_AGENT ||
          (state.appAuthentication.current.user.kyc && state.appAuthentication.current.user.kyc.verified === 1))
      ) {
        this.routeToDefault(state.appAuthentication.current.user.type)
        return
      }
      return
    }
    if (
      pathname.indexOf(
        `/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_USER}/${routeConstant.ROUTE_NAME_MAIN_USER_DETAIL}`
      ) > -1 &&
      state.appAuthentication.current.user.type === userConstant.USER_TYPE_AGENT
    ) {
      if (
        state.appAuthentication.current.user.uid !== parseInt(pathname.split('/')[pathname.split('/').length - 1], 10)
      ) {
        this.browserHistory.push(
          `/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_USER}/${routeConstant.ROUTE_NAME_MAIN_USER_DETAIL}/${state.appAuthentication.current.user.uid}`
        )
        return
      }
    }
    if (
      (pathname.indexOf(routeConstant.ROUTE_NAME_MAIN_WITHDRAW_NEW) > -1 &&
        (state.appAuthentication.current.user.type === userConstant.USER_TYPE_ADMIN ||
          state.appAuthentication.current.user.type === userConstant.USER_TYPE_ENTERPRISE)) ||
      (pathname.indexOf(routeConstant.ROUTE_NAME_MAIN_WITHDRAW_HISTORY) > -1 &&
        state.appAuthentication.current.user.type === userConstant.USER_TYPE_ENTERPRISE)
    ) {
      this.browserHistory.push(`/${routeConstant.ROUTE_NAME_MAIN}`)
      return
    }
    if (
      pathname.indexOf(`${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_WITHDRAW}`) > -1 &&
      state.appState.domain.domain.indexOf('telepro.me') === -1
    ) {
      this.browserHistory.push(`/${routeConstant.ROUTE_NAME_MAIN}`)
      return
    }
    // if (
    //   pathname.indexOf(
    //     `${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_ADMIN}/${routeConstant.ROUTE_NAME_MAIN_ADMIN_KYC}`
    //   ) > -1 &&
    //   state.appState.domain.domain.indexOf('telepro.me') === -1
    // ) {
    //   this.browserHistory.push(`/${routeConstant.ROUTE_NAME_MAIN}`)
    //   return
    // }
    if (
      pathname.indexOf(`${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_TEAM}`) > -1 &&
      (state.appAuthentication.current.user.type === userConstant.USER_TYPE_ADMIN ||
        state.appAuthentication.current.user.type === userConstant.USER_TYPE_ENTERPRISE)
    ) {
      this.browserHistory.push(`/${routeConstant.ROUTE_NAME_MAIN}`)
      return
    }
    if (!state.appAuthentication.current) {
      return
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_DASHBOARD}`) > -1 &&
      !this.hasAccessDashboard(pathname)
    ) {
      this.routeToDefault(state.appAuthentication.current.user.type)
      return
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_REPORT}`) > -1 &&
      !this.hasAccessReport(pathname)
    ) {
      this.routeToDefault(state.appAuthentication.current.user.type)
      return
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_ADMIN}`) > -1 &&
      !this.hasAccessAdmin(pathname)
    ) {
      this.routeToDefault(state.appAuthentication.current.user.type)
      return
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_COMPANY}`) > -1 &&
      !this.hasAccessCompany(pathname)
    ) {
      this.routeToDefault(state.appAuthentication.current.user.type)
      return
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_JOB}`) > -1 &&
      !this.hasAccessJob(pathname)
    ) {
      this.routeToDefault(state.appAuthentication.current.user.type)
      return
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_DATA}`) > -1 &&
      !this.hasAccessData(pathname)
    ) {
      this.routeToDefault(state.appAuthentication.current.user.type)
      return
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_KYC}/${routeConstant.ROUTE_NAME_KYC_REGISTER}`) > -1 &&
      !this.hasAccessKycRegister(pathname)
    ) {
      this.routeToDefault(state.appAuthentication.current.user.type)
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_SHOP}`) > -1 &&
      !this.hasAccessShop(pathname)
    ) {
      this.routeToDefault(state.appAuthentication.current.user.type)
    }
    if (
      pathname.indexOf(`/${routeConstant.ROUTE_NAME_MAIN}/${routeConstant.ROUTE_NAME_MAIN_INBOUND}`) > -1 &&
      !this.hasAccessInbound(pathname)
    ) {
      this.routeToDefault(state.appAuthentication.current.user.type)
    }
  }
}

export default AccessControl
