class Cookies {
  static set(name, value, options) {
    window.Cookies.set(name, value, options)
  }

  static get(name) {
    return window.Cookies.get(name)
  }

  static remove(name) {
    window.Cookies.remove(name)
  }
}

export default Cookies
