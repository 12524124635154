import React from 'react'
import { Redirect } from 'react-router'

import * as routeConstant from 'constant/route'

import DynamicImport from 'common/dynamicImport/components'

const SplashComponent = props => (
  <DynamicImport load={() => import('../modules/splash/components')}>
    {Component => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
)

const MaintainComponent = props => (
  <DynamicImport load={() => import('../modules/maintain/components')}>
    {Component => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
)
const ErrorComponent = props => (
  <DynamicImport load={() => import('../modules/error/components')}>
    {Component => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
)

const AuthenticationComponent = props => (
  <DynamicImport load={() => import('../modules/authentication/components')}>
    {Component => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
)

const MainComponent = props => (
  <DynamicImport load={() => import('../modules/main/components')}>
    {Component => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
)

const KycComponent = props => (
  <DynamicImport load={() => import('../modules/kyc/components')}>
    {Component => (Component === null ? null : <Component {...props} />)}
  </DynamicImport>
)

const AppRouter = [
  {
    path: routeConstant.ROUTE_NAME_SPLASH,
    exact: true,
    component: SplashComponent
  },
  {
    path: routeConstant.ROUTE_NAME_MAINTAIN,
    exact: true,
    component: MaintainComponent
  },
  {
    path: routeConstant.ROUTE_NAME_ERROR,
    exact: true,
    component: ErrorComponent
  },
  {
    path: `/${routeConstant.ROUTE_NAME_AUTHENTICATION}/:page(${routeConstant.ROUTE_NAME_AUTHENTICATION_SIGNIN}|${routeConstant.ROUTE_NAME_AUTHENTICATION_SIGNUP}|${routeConstant.ROUTE_NAME_AUTHENTICATION_FORGOT}|)`,
    exact: true,
    component: AuthenticationComponent
  },
  {
    path: `/${routeConstant.ROUTE_NAME_KYC}/${routeConstant.ROUTE_NAME_KYC_REGISTER}`,
    exact: true,
    component: KycComponent
  },
  {
    path: `/${routeConstant.ROUTE_NAME_MAIN}`,
    component: MainComponent
  },
  {
    path: '*',
    component: () => <Redirect to={routeConstant.ROUTE_NAME_SPLASH} />
  }
]

export default AppRouter
