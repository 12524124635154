import { createStore, combineReducers, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { combineEpics, createEpicMiddleware } from 'redux-observable'

import appConfigs from 'config'

import appStateReducers from 'store/appState/reducers'
import {
  changeAppStateInitializedEpic,
  changeAppStateDomainEpic,
  changeAppStateDisableRoutingEpic,
  changeAppStateCustomAttributesEpic
} from 'store/appState/epics'

import languageReducers from 'store/language/reducers'
import { changeLanguageEpic } from 'store/language/epics'

import themeReducers from 'store/theme/reducers'
import { changeThemeEpic } from 'store/theme/epics'

import authenticationReducers from 'store/authentication/reducers'
import { changeAuthenticatedDataEpic } from 'store/authentication/epics'

import companyReducers from 'store/company/reducers'
import { changeCompanyEpic } from 'store/company/epics'

const epicMiddleware = createEpicMiddleware()

const configureStore = () => {
  const store = createStore(
    combineReducers({
      appState: appStateReducers,
      appLanguage: languageReducers,
      appTheme: themeReducers,
      appAuthentication: authenticationReducers,
      appCompany: companyReducers
    }),
    applyMiddleware(createLogger({ predicate: () => appConfigs.LOGGER.REDUX }), epicMiddleware)
  )

  epicMiddleware.run(
    combineEpics(
      changeAppStateInitializedEpic,
      changeAppStateDomainEpic,
      changeAppStateDisableRoutingEpic,
      changeLanguageEpic,
      changeThemeEpic,
      changeAuthenticatedDataEpic,
      changeCompanyEpic,
      changeAppStateCustomAttributesEpic
    )
  )

  return store
}

export default configureStore
