export const ROUTE_NAME_SPLASH = '/'
export const ROUTE_NAME_MAINTAIN = '/maintain'
export const ROUTE_NAME_ERROR = '/error'

export const ROUTE_NAME_AUTHENTICATION = 'authentication'
export const ROUTE_NAME_AUTHENTICATION_SIGNIN = 'signin'
export const ROUTE_NAME_AUTHENTICATION_SIGNUP = 'signup'
export const ROUTE_NAME_AUTHENTICATION_FORGOT = 'forgot'

export const ROUTE_NAME_KYC = 'kyc'
export const ROUTE_NAME_KYC_REGISTER = 'register'

export const ROUTE_NAME_MAIN = 'main'

export const ROUTE_NAME_MAIN_DASHBOARD = 'dashboard'
export const ROUTE_NAME_MAIN_DASHBOARD_ADMIN = 'admin'
export const ROUTE_NAME_MAIN_DASHBOARD_ENTERPRISE = 'enterprise'
export const ROUTE_NAME_MAIN_DASHBOARD_AGENT = 'agent'
export const ROUTE_NAME_MAIN_DASHBOARD_QC = 'qc'
export const ROUTE_NAME_MAIN_DASHBOARD_WELCOME = 'welcome'

export const ROUTE_NAME_MAIN_REPORT = 'report'
export const ROUTE_NAME_MAIN_REPORT_PARTNER = 'partner'

export const ROUTE_NAME_MAIN_COMPANY = 'company'
export const ROUTE_NAME_MAIN_COMPANY_LIST = 'list'
export const ROUTE_NAME_MAIN_COMPANY_FORM = 'form'

export const ROUTE_NAME_MAIN_ADMIN = 'admin'
export const ROUTE_NAME_MAIN_ADMIN_USER = 'users'
export const ROUTE_NAME_MAIN_ADMIN_KYC = 'kyc'
export const ROUTE_NAME_MAIN_ADMIN_TEAM = 'teams'
export const ROUTE_NAME_MAIN_ADMIN_PARTNER = 'partner'
export const ROUTE_NAME_MAIN_ADMIN_TOPUP = 'topup'
export const ROUTE_NAME_MAIN_ADMIN_DEDUCT = 'deduct'
export const ROUTE_NAME_MAIN_ADMIN_CASH = 'cash'
export const ROUTE_NAME_MAIN_ADMIN_PUNISHMENT = 'punish'
export const ROUTE_NAME_MAIN_ADMIN_BANNER = 'banner'
export const ROUTE_NAME_MAIN_ADMIN_PUSH = 'push'
export const ROUTE_NAME_MAIN_ADMIN_SMS = 'sms'
export const ROUTE_NAME_MAIN_ADMIN_CALL_MANAGEMENT = 'callmanagement'
export const ROUTE_NAME_MAIN_ADMIN_JOB_MANAGEMENT = 'jobmanagement'
export const ROUTE_NAME_MAIN_ADMIN_CALL_REPORT = 'callreport'
export const ROUTE_NAME_MAIN_ADMIN_SYSTEM_MONITOR = 'monitor'
export const ROUTE_NAME_MAIN_ADMIN_CALL_CENTER_CONFIGURATION = 'call_center'
export const ROUTE_NAME_MAIN_ADMIN_REPORT = 'reports'
export const ROUTE_NAME_MAIN_ADMIN_C3B_PUSH = 'c3b_push'
export const ROUTE_NAME_MAIN_ADMIN_CALL_QC = 'callqc'

export const ROUTE_NAME_MAIN_JOB = 'job'
export const ROUTE_NAME_MAIN_JOB_LIST = 'list'
export const ROUTE_NAME_MAIN_JOB_SHARED = 'shared'
export const ROUTE_NAME_MAIN_JOB_C3B_REPORT = 'c3b_report'
export const ROUTE_NAME_MAIN_JOBS_KPI = 'job_kpi'
export const ROUTE_NAME_MAIN_JOB_FORM = 'form'
export const ROUTE_NAME_MAIN_JOB_MINE = 'mine'
export const ROUTE_NAME_MAIN_JOB_DETAIL = 'detail'
export const ROUTE_NAME_MAIN_JOB_CALL = 'call'
export const ROUTE_NAME_MAIN_JOB_CONVERSION_RATE = 'conversion_rate'
export const ROUTE_NAME_MAIN_JOB_CALL_HISTORY = 'history'
export const ROUTE_NAME_MAIN_JOB_CALL_RESULT = 'result'
export const ROUTE_NAME_MAIN_JOB_CALL__RESULT_DETAILS = 'result_details'
export const ROUTE_NAME_MAIN_JOB_CALL_DATA = 'data'
export const ROUTE_NAME_MAIN_JOB_SUBSCRIBER = 'subscriber'
export const ROUTE_NAME_MAIN_JOB_IMPORT = 'import'
export const ROUTE_NAME_MAIN_JOB_FOLLOW = 'followcall'
export const ROUTE_NAME_MAIN_JOB_FOLLOW_POOL = 'followpool'
export const ROUTE_NAME_MAIN_JOB_FOLLOW_DATA = 'followdata'
export const ROUTE_NAME_MAIN_JOB_TRANSACTIONS = 'transactions'
export const ROUTE_NAME_MAIN_JOB_DASHBOARD = 'dashboard'
export const ROUTE_NAME_MAIN_JOB_REPORT = 'report'
export const ROUTE_NAME_MAIN_JOB_STATISTIC = 'statistic'

export const ROUTE_NAME_MAIN_DATA = 'data'
export const ROUTE_NAME_MAIN_DATA_LIST = 'list'
export const ROUTE_NAME_MAIN_DATA_CONTACT = 'contact'
export const ROUTE_NAME_MAIN_DATA_MONITORING = 'monitoring'
export const ROUTE_NAME_MAIN_DATA_FACEBOOK_COMMENT_TRACKING = 'facebook_comment_tracking'

export const ROUTE_NAME_MAIN_USER = 'user'
export const ROUTE_NAME_MAIN_USER_DETAIL = 'detail'

export const ROUTE_NAME_MAIN_SHOP = 'shop'
export const ROUTE_NAME_MAIN_SHOP_PRODUCT = 'product'
export const ROUTE_NAME_MAIN_SHOP_ORDER = 'order'
export const ROUTE_NAME_MAIN_SHOP_SHIPPING = 'shipping'

export const ROUTE_NAME_MAIN_TEAM = 'team'
export const ROUTE_NAME_MAIN_TEAM_MY_TEAM = 'myteam'
export const ROUTE_NAME_MAIN_TEAM_FEATURED_TEAM = 'featured'

export const ROUTE_NAME_MAIN_WITHDRAW = 'withdraw'
export const ROUTE_NAME_MAIN_WITHDRAW_HISTORY = 'history'
export const ROUTE_NAME_MAIN_WITHDRAW_NEW = 'new'

export const ROUTE_NAME_MAIN_TRANSACTION = 'transaction'
export const ROUTE_NAME_MAIN_TRANSACTION_HISTORY = 'history'

export const ROUTE_NAME_MAIN_INBOUND = 'inbound'
export const ROUTE_NAME_MAIN_INBOUND_CONFIGURATION = 'configuration'
export const ROUTE_NAME_MAIN_INBOUND_DASHBOARD = 'dashboard'
export const ROUTE_NAME_MAIN_INBOUND_INCOMMING = 'incomming'
export const ROUTE_NAME_MAIN_INBOUND_OUTGOING = 'outgoing'
export const ROUTE_NAME_MAIN_INBOUND_TICKET = 'ticket'
export const ROUTE_NAME_MAIN_INBOUND_DETAIL = 'detail'
export const ROUTE_NAME_MAIN_INBOUND_CONTACT = 'contact'
export const ROUTE_NAME_MAIN_INBOUND_MISSED_CALL = 'missedcall'