export const CHANGE_APP_STATE_INITIALIZED_CALL = 'CHANGE_APP_STATE_INITIALIZED_CALL'
export const CHANGE_APP_STATE_INITIALIZED_RETURN = 'CHANGE_APP_STATE_INITIALIZED_RETURN'

export const CHANGE_APP_STATE_DOMAIN_CALL = 'CHANGE_APP_STATE_DOMAIN_CALL'
export const CHANGE_APP_STATE_DOMAIN_RETURN = 'CHANGE_APP_STATE_DOMAIN_RETURN'

export const CHANGE_APP_STATE_DISABLE_ROUTING_CALL = 'CHANGE_APP_STATE_DISABLE_ROUTING_CALL'
export const CHANGE_APP_STATE_DISABLE_ROUTING_RETURN = 'CHANGE_APP_STATE_DISABLE_ROUTING_RETURN'

export const CHANGE_APP_STATE_CUSTOM_ATTRIBUTES_CALL = 'CHANGE_APP_STATE_CUSTOM_ATTRIBUTES_CALL'
export const CHANGE_APP_STATE_CUSTOM_ATTRIBUTES_RETURN = 'CHANGE_APP_STATE_CUSTOM_ATTRIBUTES_RETURN'
