import { filter, map } from 'rxjs/operators'

import * as actionTypes from './actionTypes'

export const changeAppStateInitializedEpic = action$ =>
  action$.pipe(
    filter(action => action.type === actionTypes.CHANGE_APP_STATE_INITIALIZED_CALL),
    map(action => ({
      type: actionTypes.CHANGE_APP_STATE_INITIALIZED_RETURN,
      payload: { ...action.payload }
    }))
  )

export const changeAppStateDomainEpic = action$ =>
  action$.pipe(
    filter(action => action.type === actionTypes.CHANGE_APP_STATE_DOMAIN_CALL),
    map(action => ({
      type: actionTypes.CHANGE_APP_STATE_DOMAIN_RETURN,
      payload: { ...action.payload }
    }))
  )

export const changeAppStateDisableRoutingEpic = action$ =>
  action$.pipe(
    filter(action => action.type === actionTypes.CHANGE_APP_STATE_DISABLE_ROUTING_CALL),
    map(action => ({
      type: actionTypes.CHANGE_APP_STATE_DISABLE_ROUTING_RETURN,
      payload: { ...action.payload }
    }))
  )

export const changeAppStateCustomAttributesEpic = action$ =>
  action$.pipe(
    filter(action => action.type === actionTypes.CHANGE_APP_STATE_CUSTOM_ATTRIBUTES_CALL),
    map(action => ({
      type: actionTypes.CHANGE_APP_STATE_CUSTOM_ATTRIBUTES_RETURN,
      payload: { ...action.payload }
    }))
  )
