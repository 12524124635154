import * as actionTypes from './actionTypes';

const initalState = {
    current: null
};

const authenticationReducers = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_AUTHENTICATED_DATA_RETURN:
            return { current: action.payload.newAuthenticatedData };
        default:
            return { ...state };
    }
};

export default authenticationReducers;