import * as appStateConstant from 'constant/appState'
import appConfigs from 'config'
import * as actionTypes from './actionTypes'

const initalState = {
  initialized: appStateConstant.APP_STATE_INITIALIZED_NO,
  disableRouting: false,
  customAttributes: {
    tickets: [],
    contacts: []
  },
  domain: {
    domain: 'telepro.me',
    logo: `${process.env.PUBLIC_URL}/assets/images/logo.png`,
    splashLogo: `${process.env.PUBLIC_URL}/assets/images/logo-red.png`,
    title: 'TelePro',
    status: 1,
    id: null,
    type: null,
    contact: null,
    extra: appConfigs.EXTERNAL_LINKS.TELEPRO,
    description: '',
    sip_server: '{"domain":"nat.azstack.com","port":7443}'
  }
}

const appStateReducers = (state = initalState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_APP_STATE_INITIALIZED_RETURN:
      return { ...state, initialized: action.payload.newAppStateInitialized }
    case actionTypes.CHANGE_APP_STATE_DOMAIN_RETURN:
      return { ...state, domain: action.payload.newAppStateDomain }
    case actionTypes.CHANGE_APP_STATE_DISABLE_ROUTING_RETURN:
      return { ...state, disableRouting: action.payload.newAppStateDisableRouting }
    case actionTypes.CHANGE_APP_STATE_CUSTOM_ATTRIBUTES_RETURN:
      return { ...state, customAttributes: action.payload.newAppCustomAttributes }
    default:
      return { ...state }
  }
}

export default appStateReducers
