import * as actionTypes from './actionTypes';
import * as languageConstant from 'constant/language';

const initalState = {
    current: languageConstant.LANGUAGE_TYPE_VN
};

const languageReducers = (state = initalState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_LANGUAGE_RETURN:
            return { current: action.payload.newLanguage };
        default:
            return { ...state };
    }
};

export default languageReducers;